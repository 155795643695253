@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "popins";
  src: local("popins"), url("./assets/fonts/Poppins-Medium.ttf") format("woff");
}

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "popins";
}

.no-focus-outline:focus {
  outline: none;
}

/* .logo{
  color: #d48442;
} */

.active {
  --tw-text-opacity: 1;
  --tw-bg-opacity: 1;
  color: white !important;
  background-color: #2F3C7E !important;
}

/* Navbar css */
.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.accordion-backgroun {
  /* background-image: url('./assets//images//accordion-bg.jpg'); */
}

.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

/* ------------------------------------ */

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1000;
}

.icon-style {
  background-color: #2f3c7e;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 4s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #2f3c7e;
  border: 2px solid #2f3c7e;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
